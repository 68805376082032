import React, { useEffect } from 'react';
import styles from './Alert.module.less';

interface AlertProps {
  message: string;
  type?: 'success' | 'error' | 'info';
  duration?: number;
  onClose: () => void;
  children?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ message, type = 'info', duration = 3000, onClose, children }) => {
  useEffect(() => {
    if (duration > 0) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  return (
    <div className={styles.alertOverlay}>
          <div className={`${ styles.alert } ${ styles[ type ] }`}>
              <div className={styles.dotContainer}>
                  <div className={styles.dot}></div>
        <p>{message}</p>
              </div>
        
        <div className={styles.buttonGroup}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Alert;
