import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.less';
import appImg from '../../assets/img/analyzeTool.png';
import WordSearch from '../common/WordSearch';

const Home: React.FC = () => {
    const navigate = useNavigate();

    const handleSearch = ( query: string ) => {
        console.log( '搜索词汇:', query );
        navigate( `/dictionary?query=${ query }` ); // 跳转到词典页面
    };

    return (
        <div className={styles.homeContainer}>
            <section className={`${ styles.section } centerContent`}>
                <div className={styles.titleBlock}>
                    <h1 className={styles.title}>在线日语词典</h1>
                    <p className={styles.intro}>输入单词，获取详细解释、例句、发音等信息，支持日语，中文和英语。</p>
                </div>
                <WordSearch onSearch={handleSearch} />
            </section>
            <section className={`${ styles.section } ${ styles.analyzeSection }`}>
                <div className={`centerContent ${ styles.analyzeContent }`}>
                    <div className={styles.appImg}>
                        <img src={appImg} alt="日语文本读音标注" />
                    </div>
                    <div className={styles.analyzeBlock}>
                        <div className={styles.analyzeTitle}>
                            <h1>日文读音标注</h1>
                            <p>通过FuriFlow的文本分析工具，您可以轻松为日文里的汉字标注读音。</p>
                        </div>
                        <button className={styles.analyzeButton} onClick={() => navigate( '/applications/text-analyze' )}>
                            快来试试吧 →
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
