import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Applications.module.less';
import { applicationsData } from './applicationsData'; // 导入 applicationsData

const Applications: React.FC = () => {
  return (
    <div>
      <div className={styles.applicationsList}>
        {applicationsData.map(app => (
          <Link to={`/applications/${app.id}`} key={app.id} className={styles.applicationItem}>
            <img src={app.imageUrl} alt={app.name} className={styles.applicationImage} />
            <h3>{app.name}</h3>
            <p>{app.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Applications;
