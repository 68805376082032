import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Menu.module.less'; // 确保路径和文件名正确

interface MenuProps {
  isLoggedIn: boolean;
  userInfo: any;
  handleLogout: () => void;
}

const Menu: React.FC<MenuProps> = ({ isLoggedIn, userInfo, handleLogout }) => {
  return (
    <nav className={styles.menu}> {/* 确保使用了正确的类名 */}
      <div className={styles.menuLeft}>
        <ul>
                  <li><Link to="/">首页</Link></li>
                  <li><Link to="/dictionary">在线词典</Link></li>
          <li><Link to="/applications">应用</Link></li>
                  <li><Link to="/discover">发现</Link></li>
                  <li><Link to="/community">社区</Link></li>
        </ul>
      </div>
      <div className={styles.menuRight}>
        <ul>
          {isLoggedIn ? (
            <>
              
              <li><Link to="/change-password">修改密码</Link></li>
              <li><Link to="/mypage">我的页面</Link></li>
              <li><Link to="/settings">设置</Link></li>
              
              <li><button onClick={handleLogout}>退出登录</button></li>
            </>
          ) : (
            <>

            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
