import React, { useState } from 'react';
import { changePassword } from '../../libs/api';

const ChangePassword: React.FC = () => {
  const [formData, setFormData] = useState({ userId: '', newPassword: '' });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await changePassword(formData);
      console.log('密码修改成功:', result);
    } catch (error) {
      console.error('修改密码失败:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>修改密码</h2>
      <input type="text" name="userId" placeholder="用户ID" value={formData.userId} onChange={handleChange} required />
      <input type="password" name="newPassword" placeholder="新密码" value={formData.newPassword} onChange={handleChange} required />
      <button type="submit">修改密码</button>
    </form>
  );
};

export default ChangePassword;
