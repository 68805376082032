import { FuriganaTextResult } from '@/libs/type';
import React from 'react';

interface FuriganaTextProps {
  result: FuriganaTextResult
}

const FuriganaText: React.FC<FuriganaTextProps> = ( { result } ) => {

    const getPosColor = (pos:string) => {
        switch (pos) {
            case '名詞': return 'noun';
            case '動詞': return 'verb';
            case '形容詞': return 'adjective';
            default: return 'default';
        }
    }
    
  return (
    <div className="furigana-text">
          {result?.words.map( ( word, index ) => (
          <div key={index} className='word-container'>
        <ruby>
          <div className={`${getPosColor(word.pos)} surface`}>{word.surface}</div>
          <rt>{word.surface !== word.reading_katakana && word.surface !== word.reading_hiragana ? word.reading_hiragana : ""}</rt>
                  </ruby>
                  </div>
      ))}
    </div>
  );
};

export default FuriganaText;
