import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Register from '../components/Auth/Register';
import Login from '../components/Auth/Login';
import ChangePassword from '../components/Auth/ChangePassword';
import MyPage from '../components/MyPage';
import Settings from '../components/Auth/Settings';
import Discover from '../components/Discover/Discover';
import Community from '../components/Community/Community';
import Applications from '../components/Applications/Applications';
import Menu from '../components/common/Menu';
import Layout from '../components/common/Layout'; // 引入 Layout 组件
import Cookies from 'js-cookie';
import { validateToken } from '../libs/api';
import PrivacyPolicy from '../components/PrivacyPolicy'; // 导入隐私政策页面
import TermsOfService from '../components/TermsOfService'; // 导入服务条款页面
import TextAnalyze from '../components/TextAnalyze/TextAnalyze'; // 导入 TextAnalyze 组件
import Home from '../components/Home/Home'; // 导入 Home 组件
import Dictionary from '../components/Dictionary/Dictionary'; // 导入 Dictionary 组件

const AppRoutes: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('accessToken');
    if (token) {
      validateToken(token)
        .then(() => {
          setIsLoggedIn(true);
          const storedUserInfo = localStorage.getItem('userInfo');
          if (storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
          }
        })
        .catch(() => {
          setIsLoggedIn(false);
          localStorage.removeItem('userInfo');
        });
    } else {
      setIsLoggedIn(false);
      localStorage.removeItem('userInfo');
    }
  }, []);

  const handleLogout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('userId');
    localStorage.removeItem('userInfo');
    setIsLoggedIn(false);
    window.location.reload(); // 强制刷新页面
  };

  if (isLoggedIn === null) {
    return <div>Loading...</div>;
  }

  return (
    <Layout isLoggedIn={isLoggedIn} userInfo={userInfo} handleLogout={handleLogout}>
      <Routes>
        <Route path="/" element={<Home />} /> {/* 使用 Home 组件 */}
        <Route path="/applications" element={<Applications />} />
        <Route path="/applications/:id" element={<div>应用详情页面</div>} /> {/* 添加应用详情路由 */}
        <Route path="/discover" element={<Discover />} />
        <Route path="/dictionary" element={<Dictionary />} /> {/* 添加 Dictionary 路由 */}

        <Route path="/register" element={isLoggedIn ? <Navigate to="/" replace /> : <Register />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to="/" replace /> : <Login />} />
        <Route path="/change-password" element={isLoggedIn ? <ChangePassword /> : <Navigate to="/login" replace />} />
        <Route path="/mypage" element={isLoggedIn ? <MyPage /> : <Navigate to="/login" replace />} />
        <Route path="/settings" element={isLoggedIn ? <Settings /> : <Navigate to="/login" replace />} />
        <Route path="/community" element={isLoggedIn ? <Community /> : <Navigate to="/login" replace />} />
        <Route path="/forgot-password" element={<div>忘记密码页面</div>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* 添加隐私政策路由 */}
        <Route path="/terms-of-service" element={<TermsOfService />} /> {/* 添加服务条款路由 */}
        <Route path="/applications/text-analyze" element={<TextAnalyze />} /> {/* 添加 Text Analyze 路由 */}
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
