import React from 'react';

const Discover: React.FC = () => {
  return (
    <div>
      <h2>发现页面</h2>
      <p>这里是游客可以访问的发现页面。</p>
      {/* 你可以在这里添加更多的内容 */}
    </div>
  );
};

export default Discover;
