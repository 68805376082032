import React, { useEffect, useState } from 'react';

const MyPage: React.FC = () => {
  const [userInfo, setUserInfo] = useState<any>(null);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []);

  if (!userInfo) {
    return <div>Loading user information...</div>;
  }

  return (
    <div>
      <h2>用户信息</h2>
      <p>用户ID: {userInfo.userId || '未提供'}</p> {/* 显示用户ID */}
      <p>姓名: {userInfo.name || '未提供'}</p>
      <p>电子邮件: {userInfo.email || '未提供'}</p>
      <p>电话: {userInfo.phone || '未提供'}</p> {/* 假设有电话信息 */}
      <p>地址: {userInfo.address || '未提供'}</p> {/* 假设有地址信息 */}
      {/* 你可以在这里添加更多的用户信息 */}
    </div>
  );
};

export default MyPage;
