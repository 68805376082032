import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import WordSearch from '../common/WordSearch';
import Alert from '../common/Alert';
import styles from './Dictionary.module.less';
import { searchWord } from '../../libs/api';
import Button from '../common/Button';

const Dictionary: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ query, setQuery ] = useState( '' );
    const [ result, setResult ] = useState<any>( null );
    const [ showAlert, setShowAlert ] = useState( false );

    const handleSearch = async ( query: string ) => {
        if ( query.length > 30 ) {
            setShowAlert( true );
            return;
        }
        navigate( `/dictionary?query=${ query }` );
        try {
            const data = await searchWord( query );
            setResult( data );
        } catch ( error ) {
            console.error( '查词失败:', error );
        }
    };

    useEffect( () => {
        const params = new URLSearchParams( location.search );
        const queryParam = params.get( 'query' );
        if ( queryParam ) {
            setQuery( queryParam );
            handleSearch( queryParam );
        }
    }, [ location.search ] );

    const handleAlertClose = () => {
        setShowAlert( false );
    };

    const handleAlertConfirm = () => {
        navigate( `/applications/text-analyze?text=${ query }` );
    };

    function formatEntry(entry:string) {
    // 使用正则表达式将 `1` `2` 作为粗体标签处理
    const formatted = entry
        .replace(/`1`/g, '')
        .replace(/`2`/g, '')
        .replace(/<br>/g, '<br>');

    // 包裹在字典条目容器中
    return `<div class="dictionary-entry">${formatted}</div>`;
}
    return (
        <div className='centerContent'>
            <div className={styles.dictionaryContainer}>
                <h1 className={styles.title}>在线日语词典</h1>
                {/* <p className={styles.description}>输入单词，获取详细解释、例句、发音等信息，支持日语，中文和英语。</p> */}
                <div className={styles.searchSection}>
                    <WordSearch onSearch={handleSearch} initialQuery={query} />
                </div>
                {result && result.message === 'success' && (
                    <div className={styles.resultContent} dangerouslySetInnerHTML={{ __html: formatEntry(result.result.m) }}>
                    </div>
                )}
                {showAlert && (
                    <Alert
                        message="您输入的内容过长，建议进行文本分析。"
                        type="info"
                        onClose={handleAlertClose}
                        duration={0} // 不自动关闭
                    >
                        <Button className="grayButton" onClick={handleAlertClose}>关闭</Button>
                        <Button className="primaryButton" onClick={handleAlertConfirm}>好的</Button>
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default Dictionary;
