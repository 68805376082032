import React from 'react';

const Community: React.FC = () => {
  return (
    <div>
      <h2>社区页面</h2>
      <p>欢迎来到社区！这里是用户可以交流和分享的地方。</p>
      {/* 你可以在这里添加更多的内容 */}
    </div>
  );
};

export default Community;
