import axios from 'axios';

// 创建一个 axios 实例
const api = axios.create({
  baseURL: '/api', // 使用/api作为基础URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// 注册用户
export const registerUser = async (userData: { name: string; email: string; password: string }) => {
  try {
    const response = await api.post('/users', userData);
    return response.data;
  } catch (error) {
    console.error('注册失败:', error);
    throw error;
  }
};

// 用户登录
export const loginUser = async (loginData: { email: string; password: string }) => {
  try {
    const response = await api.post('/users/login', loginData);
    return response.data;
  } catch (error) {
    console.error('登录失败:', error);
    throw error;
  }
};

// 修改密码
export const changePassword = async (passwordData: { userId: string; newPassword: string }) => {
  try {
    const response = await api.post('/users/change-password', passwordData);
    return response.data;
  } catch (error) {
    console.error('修改密码失败:', error);
    throw error;
  }
};

// 验证 token
export const validateToken = async (token: string) => {
  try {
    const response = await api.post('/auth/validate-token', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Token validation failed:', error);
    throw error;
  }
};

// 获取用户信息
export const getUserInfo = async () => {
  try {
    const response = await api.get('/users/me'); // 使用推断出的地址
    return response.data;
  } catch (error) {
    console.error('获取用户信息失败:', error);
    throw error;
  }
};

export const analyzeText = async (text: string) => {
  try {
    const response = await axios.post('/api/tools/analyze', { text });
    return response.data.result;
  } catch (error) {
    console.error('分析失败:', error);
    throw error;
  }
};

// 查词
export const searchWord = async (query: string) => {
  try {
    const response = await api.post('/dictionary/search', { query });
    return response.data;
  } catch (error) {
    console.error('查词失败:', error);
    throw error;
  }
};