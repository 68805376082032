import React, { useEffect, useState } from 'react';
import './TextAnalyze.less';
import { analyzeText } from '../../libs/api';
import FuriganaText from './FuriganaText';
import { FuriganaTextResult } from '@/libs/type';
import { defaultText } from '@/libs/data';

const TextAnalyze: React.FC = () => {
    const [ text, setText ] = useState( defaultText );
    const [ result, setResult ] = useState<FuriganaTextResult[] | null>( null );
    const [ error, setError ] = useState<string | null>( null );
    const [ isTextareaVisible, setTextareaVisible ] = useState( true );
    const [ isAnalyzeButtonVisible, setAnalyzeButtonVisible ] = useState( false );
    const [ isAnalyzing, setIsAnalyzing ] = useState( false ); // 新增状态

    const handleTextChange = ( event: React.ChangeEvent<HTMLTextAreaElement> ) => {
        setText( event.target.value );
        adjustTextareaHeight( event.target );
    };
    const handleAnalyze = async () => {
        const containsJapanese = /[\u3040-\u30FF\u4E00-\u9FAF]/.test( text );

        if ( !containsJapanese ) {
            setError( '请输入有效的日语文本' );
            return;
        }
        setError( null );
        setIsAnalyzing( true ); // 开始分析
        try {
            const paragraphs = text.split( '\n' ).filter( paragraph => paragraph.trim() !== '' );
            const results = await Promise.all( paragraphs.map( paragraph => analyzeText( paragraph ) ) );
            setResult( results );
            if ( text !== defaultText ) {
                setTextareaVisible( false );
            }
        } catch ( error ) {
            console.error( '分析失败:', error );
            setResult( [] );
        } finally {
            setIsAnalyzing( false ); // 分析结束
        }
    };

    useEffect( () => {
        handleAnalyze()
    }, [] );

    const adjustTextareaHeight = ( textarea: HTMLTextAreaElement ) => {
        textarea.style.height = 'auto'; // 重置高度
        textarea.style.height = `${ textarea.scrollHeight + 50 }px`; // 设置为内容高度
    };

    useEffect( () => {
        const textarea = document.querySelector( '.text-input' ) as HTMLTextAreaElement;
        if ( textarea ) {
            adjustTextareaHeight( textarea );
        }
        if ( text !== defaultText ) {
            setAnalyzeButtonVisible( true );
        } else {
            setAnalyzeButtonVisible( false );
        }
    }, [ text ] );

    const handleReset = () => {
        setText( '' );
        setResult( null );
        setError( null );
        setTextareaVisible( true ); // 重置时显示输入框
        setAnalyzeButtonVisible( true );
    };

    const handleSave = () => {
        console.log( '保存结果:', result );
    };

    return (
        <div className="centerContent">
            <div className="text-analyze-header">
                <p>→ 这是一个可以为日语文本分析并标注假名读音的工具。
                    {isTextareaVisible ? <span>👇输入自定义文本</span> :
                        <span className="input-text-button" onClick={() => setTextareaVisible( true )}> 点击这里输入/修改文本</span>}
                </p>
            </div>

            <main className="text-analyze-main">
                {isTextareaVisible && (
                    <div className="text-analyze-container">

                        <textarea
                            className="text-input"
                            value={text}
                            onChange={handleTextChange}
                            placeholder="请输入文本（最多1000字）"
                        />


                        <div className="button-group">
                            {text.trim() !== "" && isTextareaVisible &&
                                <span className="clear-button" onClick={handleReset}>
                                    重置
                                </span>
                            }
                            {isAnalyzeButtonVisible &&
                                <button
                                    className="analyze-button" onClick={handleAnalyze} disabled={isAnalyzing || text.trim() === ""}>
                                    分析
                                </button>
                            }
                        </div>

                        {error && <div className="error-message">{error}</div>}

                        {isAnalyzing && <div className="analyzing-message">分析中......</div>}
                    </div>
                )}
                {Array.isArray( result ) && result.map( ( paragraphResult, index ) => (
                    <div key={index} className="text-analyze-container">
                        <FuriganaText result={paragraphResult} />
                    </div>
                ) )}
            </main>
        </div>
    );
};

export default TextAnalyze;
