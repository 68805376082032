import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>服务条款</h1>
      <p>这里是您的服务条款内容。请根据您的需求进行编辑。</p>
    </div>
  );
};

export default TermsOfService;
