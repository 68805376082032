import React, { useEffect, useState } from 'react';
import styles from './WordSearch.module.less';

interface WordSearchProps {
    onSearch: ( query: string ) => void;
    initialQuery?: string;
}

const WordSearch: React.FC<WordSearchProps> = ( { onSearch, initialQuery = '' } ) => {
    const [ query, setQuery ] = useState( initialQuery );

    const handleInputChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setQuery( event.target.value );
    };
    const handleSearch = () => {
        if (query) {
            onSearch(query);
        }
    };

    useEffect(() => {
        setQuery(initialQuery || '');
    }, [initialQuery]);

    return (
            <div className={styles.searchSection}>
                <input
                    type="text"
                    value={query}
                    onChange={handleInputChange}
                    placeholder="输入单词"
                    className={styles.searchInput}
                />
                <button onClick={handleSearch} className={styles.searchButton}>
                    查词
                </button>
            </div>
    );
};

export default WordSearch;
