import React from 'react';
import styles from './Settings.module.less'; // 引入 CSS Module

const Settings: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2>设置页面</h2>
      <p>这里是用户的设置页面。</p>
      {/* 你可以在这里添加更多的设置选项 */}
    </div>
  );
};

export default Settings;
