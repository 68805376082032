import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate
import { loginUser } from '../../libs/api';
import { AES } from 'crypto-js'; // 使用 AES 进行加密
import Cookies from 'js-cookie'; // 引入 js-cookie
import Button from '../common/Button'; // 引入通用按钮组件
import styles from './Login.module.less'; // 使用 styles 导入

const Login: React.FC = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // 使用 useNavigate

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // 重置错误信息

    // 使用 AES 加密密码
    const encryptedPassword = AES.encrypt(formData.password, 'your-secret-key').toString();

    try {
      const result = await loginUser({ ...formData, password: encryptedPassword });
      console.log('登录成功:', result);

      // 将 accessToken 和 userId 存储到 cookie 中
      Cookies.set('accessToken', result.accessToken, { expires: 7 }); // 设置过期时间为7天
      Cookies.set('userId', result.userId, { expires: 7 });

      // 将 userId 和其他用户信息存储到 Local Storage 中
      localStorage.setItem('userInfo', JSON.stringify({
        userId: result.userId,
        name: result.name,
        email: result.email,
        phone: result.phone, // 假设后端返回了电话信息
        address: result.address, // 假设后端返回了地址信息
      }));

      // 登录成功后强制跳转到首页
      window.location.href = '/';
    } catch (error) {
      console.error('登录失败:', error);
      setError('登录失败，请检查您的凭据。');
    }
  };

  return (
    <div className={styles['login-container']}>
      <form onSubmit={handleSubmit} className={styles['login-form']}>
        <h2>登录</h2>
        <input type="email" name="email" placeholder="电子邮件" value={formData.email} onChange={handleChange} required />
        <input type="password" name="password" placeholder="密码" value={formData.password} onChange={handleChange} required />
        <Button type="submit" onClick={handleSubmit}>登录</Button> {/* 使用通用按钮组件 */}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
