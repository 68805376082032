import './Button.module.less'; // 引入样式

interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void; // 修改类型以接受带参数的函数
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ onClick, children, type = 'button', className = '' }) => {
  return (
    <button type={type} onClick={onClick} className={`common-button ${className}`}>
      {children}
    </button>
  );
};

export default Button;
