import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate
import { registerUser } from '../../libs/api';
import styles from './Register.module.less'; // 导入样式

const Register: React.FC = () => {
  const [formData, setFormData] = useState({ email: '', password: '', name: '' });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // 使用 useNavigate

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // 重置错误信息

    try {
      const result = await registerUser(formData);
      console.log('注册成功:', result);
      navigate('/login'); // 注册成功后跳转到登录页面
    } catch (error) {
      console.error('注册失败:', error);
      setError('注册失败，请检查您的信息。');
    }
  };

  return (
    <div className={styles['register-container']}>
      <form onSubmit={handleSubmit} className={styles['register-form']}>
        <h2>注册</h2>
        <input type="text" name="name" placeholder="用户名" value={formData.name} onChange={handleChange} required />
        <input type="email" name="email" placeholder="电子邮件" value={formData.email} onChange={handleChange} required />
        <input type="password" name="password" placeholder="密码" value={formData.password} onChange={handleChange} required />
        <button type="submit">注册</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className={styles['privacy-links']}>
          <p>注册即表示您同意我们的 <a href="/privacy-policy" target="_blank">隐私政策</a> 和 <a href="/terms-of-service" target="_blank">服务条款</a>。</p>
        </div>
      </form>
    </div>
  );
};

export default Register;
