import React, { ReactNode } from 'react';
import styles from './Layout.module.less';
import Menu from './Menu'; // 修正 Menu 组件的导入路径
import { ReactComponent as Logo } from '../../assets/logo.svg'; // 使用 ReactComponent 作为命名空间
import { Link } from 'react-router-dom';


interface LayoutProps {
    children: ReactNode;
    isLoggedIn: boolean; // 添加 isLoggedIn 属性
    userInfo: any; // 添加 userInfo 属性
    handleLogout: () => void; // 添加 handleLogout 属性
}

const Layout: React.FC<LayoutProps> = ( { children, isLoggedIn, userInfo, handleLogout } ) => {
    return (
        <div className={styles.layoutContainer}>
            <header className={styles.layoutHeader}>
                <div className={styles.logoContainer}>
                    <Logo className={styles.logo} /> {/* 使用 SVG 组件 */}
                    <div className={styles.headerRight}>
                        {isLoggedIn ? <span>欢迎, {userInfo?.name || '用户'}!</span>: <div>
                            <span><Link to="/register">注册</Link></span>
              <span> | </span>
              <span><Link to="/login">登录</Link></span>
            </div>}
                    </div>
                </div>
                <Menu isLoggedIn={isLoggedIn} userInfo={userInfo} handleLogout={handleLogout} /> {/* 包含 Menu 组件 */}
            </header>
            <main className={styles.layoutContent}>
                {children}
            </main>
            <footer className={styles.layoutFooter}>
                © 2024 Macshion
            </footer>
        </div>
    );
};

export default Layout;
