export const applicationsData = [
  {
    id: 'text-analyze',
    name: '文本分析',
    description: '导入日语文本，获得汉字注音、词汇等级标注、朗读音频。',
    imageUrl: '/assets/img/app1.png',
  },
  {
    id: 2,
    name: '应用2',
    description: '这是应用2的描述。',
    imageUrl: '/assets/img/app2.png',
  },
  {
    id: 3,
    name: '应用3',
    description: '这是应用3的描述。',
    imageUrl: '/assets/img/app3.png',
  },
  {
    id: 4,
    name: '应用4',
    description: '这是应用4的描述。',
    imageUrl: '/assets/img/app4.jpg',
  },
  {
    id: 5,
    name: '应用5',
    description: '这是应用5的描述。',
    imageUrl: '/assets/img/app5.jpg',
  },
  {
    id: 6,
    name: '应用6',
    description: '这是应用6的描述。',
    imageUrl: '/assets/img/app6.jpg',
  },
  {
    id: 7,
    name: '应用7',
    description: '这是应用7的描述。',
    imageUrl: '/assets/img/app4.jpg',
  },
  {
    id: 8,
    name: '应用8',
    description: '这是应用8的描述。',
    imageUrl: '/assets/img/app5.jpg',
  },
  {
    id: 9,
    name: '应用9',
    description: '这是应用9的描述。',
    imageUrl: '/assets/img/app6.jpg',
  },
  {
    id: 10,
    name: '应用10',
    description: '这是应用10的描述。',
    imageUrl: '/assets/img/app6.jpg',
  },
  {
    id: 11,
    name: '应用11',
    description: '这是应用11的描述。',
    imageUrl: '/assets/img/app5.jpg',
  },
  {
    id: 12,
    name: '应用12',
    description: '这是应用12的描述。',
    imageUrl: '/assets/img/app4.jpg',
    },
  {
    id: 1,
    name: '应用1',
    description: '这是应用1的描述。',
    imageUrl: '/assets/img/app1.png',
  },
  {
    id: 2,
    name: '应用2',
    description: '这是应用2的描述。',
    imageUrl: '/assets/img/app2.png',
  },
  {
    id: 3,
    name: '应用3',
    description: '这是应用3的描述。',
    imageUrl: '/assets/img/app3.png',
  },
  {
    id: 4,
    name: '应用4',
    description: '这是应用4的描述。',
    imageUrl: '/assets/img/app4.jpg',
  },
  {
    id: 5,
    name: '应用5',
    description: '这是应用5的描述。',
    imageUrl: '/assets/img/app5.jpg',
  },
  {
    id: 6,
    name: '应用6',
    description: '这是应用6的描述。',
    imageUrl: '/assets/img/app6.jpg',
  },
  {
    id: 7,
    name: '应用7',
    description: '这是应用7的描述。',
    imageUrl: '/assets/img/app4.jpg',
  },
  {
    id: 8,
    name: '应用8',
    description: '这是应用8的描述。',
    imageUrl: '/assets/img/app5.jpg',
  },
  {
    id: 9,
    name: '应用9',
    description: '这是应用9的描述。',
    imageUrl: '/assets/img/app6.jpg',
  },
  {
    id: 10,
    name: '应用10',
    description: '这是应用10的描述。',
    imageUrl: '/assets/img/app6.jpg',
  },
  {
    id: 11,
    name: '应用11',
    description: '这是应用11的描述。',
    imageUrl: '/assets/img/app5.jpg',
  },
  {
    id: 12,
    name: '应用12',
    description: '这是应用12的描述。',
    imageUrl: '/assets/img/app4.jpg',
  },
];
